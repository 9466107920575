import React, { useState, useEffect } from 'react';

const HowItWorks = ({ asset }) => {
  // const [transferCode, setTransferCode] = useState(null);
  const instructions = [
    {
      id: 1,
      title: 'Join',
      description: 'By entering your email, you’re a member - welcome! ',
    },
    {
      id: 2,
      title: 'Check your email',
      description: 'You’ll get an email from us with your login link. Click through to validate your account and start exploring your membership!',
    },
    {
      id: 3,
      title: 'Access perks',
      description: 'Enjoy exclusive benefits like VIP discounts and perks at select local businesses, special event invitations, exciting contests and giveaways, and access to unique real estate market opportunities and insider insights. The Biggar Club is designed to bring the most value — and fun! — to our preferred community of friends, neighbours, and clients, all in one place. ',
    },
  ];

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="how-it-works__wrapper">
      <div id="startContent" className="how-it-works">
        <div className="how-it-works__title">
          How it works
        </div>
        <div className="how-it-works__list">
          {instructions.map((step, index) => (
            <div key={step.id} className="how-it-works__item">
              <div className="how-it-works__number">{index + 1}</div>
              <div className="how-it-works__step-title">{step.title}</div>
              <div className="how-it-works__step-description">{step.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default HowItWorks;
