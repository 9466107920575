import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Throbber from '../throbber';
import AccountManager from '../../managers/Account';
import SimpleModal from '../modal/modal';
import Slider from '../slider';
import LoginForm from '../account/loginForm';

const emailPattern = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
const emailAliasPattern = /^[^@+]+@[^@]+\.[^@]+$/;

const EDropLanding = ({ lang, onTransferDetailsLoaded, transferToken }) => {
  const [transferCode, setTransferCode] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [previewData, setPreviewData] = useState(null);
  const [emailExists, setEmailExists] = useState(false);
  const [isVerifiedUser, setIsVerifiedUser] = useState(true);
  const [emailAddress, setEmailAddress] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [loginEmailAddress, setLoginEmailAddress] = useState('');
  const [claimState, setClaimState] = useState(null);
  const [verifiedCode, setVerifiedCode] = useState(null);
  const [showLoginLinkModal, setShowLoginLinkModal] = useState(false);

  const copyStrings = {
    en: {
      emailPlaceholder: 'Enter your email address',
      emailCTA: 'Join',
      acceptTerms1: 'I agree to the',
      acceptTerms2: 'terms & privacy consent',
      learnMore: 'Learn more',
    },
  };
  const scrollToContent = () => {
    const content = document.getElementById('startContent');
    if (content) {
      window.scrollTo({
        top: content.offsetTop - 90,
        behavior: 'smooth',
      });
    }
  };

  const setHeroHeight = () => {
    const hero = document.getElementById('storefrontHero');
    if (hero) {
      hero.style.height = window.innerHeight - 50 + 'px';
    }
  };

  const clearAllTransferCookies = () => {
    Object.entries(localStorage).map((obj) => {
      const key = obj[0];
      if (key.includes('MEMBERSHIP_TRANSFER')) {
        localStorage.removeItem(key);
      }
      return true;
    });
  };

  const getTransferCodePreview = async (previewCode) => {
    setIsLoading(true);
    let alreadyClaimed = false;
    
    if (localStorage.getItem('NFT_TOKEN_TRANSFERRED_' + previewCode)) {
      alreadyClaimed = true;
    }
    if (!alreadyClaimed) {
      const preview = await AccountManager.getTransferDetailsV3(null, previewCode);
      if (preview && preview.success === false) {
        toast.error('Transfer code not found. Please check your code and try again.');
        setPreviewData(null);
        clearAllTransferCookies();
      } else if (preview.details) {
        // tell the parent edrop container we're good
        onTransferDetailsLoaded(preview.details);
        setPreviewData(preview.details);
        setVerifiedCode(previewCode);
        // localStorage.setItem('MEMBERSHIP_TRANSFER_' + previewCode, previewCode);
      } else {
        toast.error('Transfer code not found. Please check your code and try again.');
        setPreviewData(null);
        clearAllTransferCookies();
      }
    } else {
      setClaimState('claimed');
      const preview = await AccountManager.getTransferDetailsV3(null, previewCode);
      if (preview && preview.asset) {
        setPreviewData(preview.details);
        onTransferDetailsLoaded(preview.details);
      } else {
        setPreviewData(null);
      }
      clearAllTransferCookies();
    }
    setIsLoading(false);
  };

  const createLoginLinkAccount = async () => {
    const payload = {
      email: emailAddress,
      brandId: process.env.CREATOR_ID,
      membershipId: process.env.ASSET_ID,
    };
    // check for a referralCode. Then remove it after use.
    if (sessionStorage.getItem('ethos_referralCode')) {
      payload.referralCode = sessionStorage.getItem('ethos_referralCode');
      sessionStorage.removeItem('ethos_referralCode');
    }
    const createAccount = await AccountManager.VerifiedLogin(payload);
    // userLoginTypePreference types.
    // 0 - login link
    // 1 - password
    if (createAccount && createAccount.success) {
      setIsLoading(false);
      setShowModal(false);
      setShowLoginLinkModal(true);
      toast.success('Success! A login link has been sent to your inbox.');
    }
  };

  const createUnverifiedAccount = async () => {
    const payload = {
      email: emailAddress,
      creatorId: process.env.CREATOR_ID,
      assetId: process.env.ASSET_ID,
    };
    // check for a referralCode. Then remove it after use.
    if (sessionStorage.getItem('ethos_referralCode')) {
      payload.referralCode = sessionStorage.getItem('ethos_referralCode');
      sessionStorage.removeItem('ethos_referralCode');
    }
    const createAccount = await AccountManager.createUnverifiedAccount(payload);
    if (createAccount && createAccount.token) {
      // authenticate the user with the token and redirect them to the collections page
      AccountManager.setLoginState(createAccount.token);
      window.location.href = '/account/collection';
    } else {
      toast.error('Something went wrong. Please try again later.');
    }
  };

  const sendUnvverifiedEmail = async () => {
    setIsVerifiedUser(false);
    const payload = {
      creatorId: process.env.CREATOR_ID,
      email: emailAddress,
    };
    await AccountManager.sendLoginEmail(payload);
  };

  const checkEmailAddress = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!emailPattern.test(emailAddress)) {
      toast.error('Please enter a valid email address.');
      setIsLoading(false);
    } else if (!agreeTerms) {
      toast.error('Please agree to the terms and conditions.');
      setIsLoading(false);
    } else if (process.env.VERIFIED_ACCOUNTS_ONLY) {
      setShowModal(true);
      setEmailExists(false);
      setIsLoading(true);
      await createLoginLinkAccount();
    } else {
      localStorage.setItem('MEMBERSHIP_TRANSFER_' + transferToken, transferToken);
      setShowModal(true);
      setEmailExists(false);
      setIsLoading(true);
      const checkEmail = await AccountManager.emailSearch(null, emailAddress);
      if (checkEmail && checkEmail.emailExists === true) {
        if (!checkEmail.isVerified) {
          sendUnvverifiedEmail();
        }
        setEmailExists(true);
        setIsLoading(false);
      } else {
        await createUnverifiedAccount();
      }
    }
  };

  const onEnterKey = (e) => {
    if (e.keyCode === 13) {
      checkEmailAddress();
    }
  };

  const handleEmailChange = (e) => {
    setEmailAddress(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    let isChecked = false;
    if (e.target.checked) {
      isChecked = true;
    } else {
      isChecked = false;
    }
    setAgreeTerms(isChecked);
  };

  const handleStickySignup = () => {
    const stickySignup = document.getElementById('stickySignup');
    if (!claimState) {
      window.addEventListener('scroll', () => {
        if (window.scrollY > 100) {
          stickySignup.classList.add('-show');
        } else {
          stickySignup.classList.remove('-show');
        }
      });
    }
  };

  const loginSuccess = async () => {
    const successUrl = window.location.href + '#login=success';
    setShowSlider(false);
    if (process.env.E_DROP_ONLY === 'true') {
      window.location.href = '/account/collection';
    } else {
      window.location.href = successUrl;
      window.location.reload();
    }
  };

  const loginFailed = (error) => {
    toast.error(error || 'Oops! Something went wrong. Try again later.');
  };

  const scrollToTop = (e) => {
    e.preventDefault();
    // const scrollToElement = document.getElementById(element);
    // scrollToElement.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const openLoginModal = (e) => {
    e.preventDefault();
    setShowModal(false);
    // alert('hello');
    setLoginEmailAddress(emailAddress);
    setShowSlider(true);
  };

  useEffect(() => {
    // on mount
    setHeroHeight();
    window.addEventListener('resize', setHeroHeight);
  }, []);

  useEffect(() => {
    if (transferToken) {
      if (localStorage.getItem('NFT_TOKEN_TRANSFERRED_' + transferToken)) {
        // setClaimState('claimed');
      }
    }
  }, [transferToken]);
  useEffect(() => {
    if (previewData) {
      handleStickySignup();
    }
  }, [previewData]);

  return (
    <div
        id="storefrontHero"
        className="storefront__hero"
        style={{
          backgroundImage: 'url(' + process.env.HERO_LANDING_BACKGROUND + ')',
        }}>
      <Slider isOpen={showSlider} onBeforeClose={() => setShowSlider(false)}>
        <LoginForm
          prepopulateEmail={loginEmailAddress}
          onSuccess={() => loginSuccess()}
          onFail={(error) => loginFailed(error)}
          onRegisterButton={() => setShowSlider(false)} />
      </Slider>
      <SimpleModal isOpen={showLoginLinkModal} onBeforeClose={() => setShowLoginLinkModal(false)}>
        <div>
          <div className="account-subheading -slim-padding">
            A log in link has been sent to your inbox. Follow the instructions in the email to log in!
          </div>
          <div className="form-cta">
            <button
              type="button"
              onClick={() => setShowLoginLinkModal(false)}
              className="button">
              Close
            </button>
          </div>
        </div>
      </SimpleModal>
      <SimpleModal isOpen={showModal} onBeforeClose={() => setShowModal(false)}>
        {isLoading
          && <Throbber throbberText="Joining membership..." />}
        {!isLoading
        && (
        <div>
          {emailExists
            ? (
              <div>
                {isVerifiedUser ? (
                  <>
                    <div className="account-subheading -slim-padding">
                      An account with this email already exists. Log in now to join this loyalty program!&nbsp;
                    </div>
                    <div className="form-cta">
                      <a
                    href="#"
                    onClick={(e) => openLoginModal(e)}
                    className="button">
                        Log in
                      </a>
                      {/* <Link
                    to="/login"
                    className="button">
                    Log in
                  </Link> */}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="account-subheading -slim-padding">
                      You are already a member! A log in link has been sent to your inbox. Follow the instructions in the email to log in!
                    </div>
                    <div className="form-cta">
                      <button
                        type="button"
                        onClick={() => setShowModal(false)}
                        className="button">
                        Close
                      </button>
                    </div>
                  </>
                )}
                
              </div>
            )
            : (
              <div>Loader</div>
            )}
        </div>
        )}
      </SimpleModal>
      <div
        className="storefront__hero--inner">
        <div className="custom__hero--content">
          <img className="custom-landing__logo" src={process.env.LARGE_LOGO} alt={process.env.SITE_NAME} width={process.env.LARGE_LOGO_WIDTH} />
          <h1 className="custom-landing__title">
            {process.env.CUSTOM_EDROP_HEADER_IMAGE ? (
              <>
                <img className="custom-landing__header--image" src={process.env.CUSTOM_EDROP_HEADER_IMAGE} alt={process.env.CUSTOM_EDROP_TITLE} />
                <img className="custom-landing__header--image -mobile" src={process.env.CUSTOM_EDROP_HEADER_IMAGE_MOBILE} alt={process.env.CUSTOM_EDROP_TITLE} />
              </>
            )
              : (
                <>
                  {process.env.CUSTOM_EDROP_TITLE}
                </>
              )}

          </h1>
          <div className="custom-landing__subtitle">
            {process.env.CUSTOM_EDROP_SUBTITLE}
          </div>

          <div className="custom-landing__cta">
            <div>
              {claimState === 'claimed'
              && (
              <div className="storefront__hero--claim-state">
                <div className="icon">
                  <img src="https://assets.heyethos.com/ethos/v2/ui/icon-warning.svg" alt="Warning" />
                </div>
                <div className="content">
                  <p>Sorry, access through this link as already been claimed. If you have already claimed access, log in to access it.</p>
                </div>
                <div className="storefront__hero--claim-state__overlay"></div>
              </div>
              )}
              {claimState !== 'claimed'
              && (
              <div>
                <div className="storefront__hero--email-capture">
                  <div className="email-capture__input">
                    <div className="storefront__hero--email-capture--overlay"></div>
                    <input
                      type="text"
                      placeholder={copyStrings[lang].emailPlaceholder}
                      value={emailAddress}
                      onChange={(e) => handleEmailChange(e)}
                      onKeyUp={(e) => onEnterKey(e)} />
                  </div>
                  <div className="email-capture__cta">
                    <button
                      type="button"
                      className="email-capture__button"
                      onClick={(e) => checkEmailAddress(e)}>
                      {copyStrings[lang].emailCTA}
                    </button>
                  </div>
                </div>
                <div className="storefront__hero--accept-terms">
                  <input
                    id="registerAcceptTerms"
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e)} />
                  <label htmlFor="registerAcceptTerms">
                    {copyStrings[lang].acceptTerms1}
                    {' '}
                    <a href="https://www.heyethos.com/terms-of-service" target="_blank" rel="noreferrer">{copyStrings[lang].acceptTerms2}</a>
                    .
                  </label>
                </div>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
      
      <div className="hero--more-arrow" onClick={() => scrollToContent()}>
        <div className="more-arrow-bounce">
          {copyStrings[lang].learnMore}
          <img src="https://assets.heyethos.com/bonlook/landing/icon-down-arrow.png" alt="Learn more" />
        </div>
      </div>
    </div>
  );
};
export default EDropLanding;
