/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

function TaylorBiggarFAQ({ noHeader, lang }) {
  const [questions, setQuestions] = useState([]);
  const toggleAnswer = (id, e) => {
    e.preventDefault();
    const cloneQuestions = [...questions];
    cloneQuestions.forEach((faq) => {
      if (faq.id === id) {
        faq.isOpen = !faq.isOpen;
      }
    });
    setQuestions(cloneQuestions);
  };

  const faq = {
    en: [
      {
        id: 1,
        title: 'How do I log in?',
        answer: 'Your email will contain a login link. Click through to access your membership.',
      },
      {
        id: 2,
        title: 'How do I access my benefits?',
        answer: 'Once you’re logged in, click on your membership and your benefits will appear. Scroll through to find access to your exclusive discounts, insights, and more!',
      },
      {
        id: 3,
        title: 'How will I get notified when new benefits are available?',
        answer: 'We\'ll make sure to keep you in the loop and notify you as soon as new benefits become available through email or SMS.',
      },
      {
        id: 4,
        title: 'Can I invite others to join?',
        answer: 'Access to the Biggar Club is currently invite-only and reserved for our closest friends, clients, and community members.<br /><br />However, we will let you know when you can directly share some of your benefits — sharing is caring! ',
      },
      {
        id: 5,
        title: 'Have a question?',
        answer: 'Contact our team – <a href="mailto:taylor@gobiggargohome.com">taylor@gobiggargohome.com</a>',
      },
    ],
  };

  useEffect(() => {
    setQuestions(faq.en);
  }, []);

  return (
    <div className="asset-faq">
      {!noHeader
      && <h2 className="digital-asset__subheader -center">FAQ</h2>}
      {questions.map((faq) => (
        <div
          key={faq.id}
          className="asset-faq__item">
          {faq.isOpen
            ? (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                -
              </a>
            )
            : (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                +
              </a>
            )}
          <div
            className="asset-faq__item--question"
            onClick={(e) => toggleAnswer(faq.id, e)}>
            {faq.title}
          </div>
          <div
            className={faq.isOpen ? 'asset-faq__item--answer -open' : 'asset-faq__item--answer'}
            dangerouslySetInnerHTML={{ __html: faq.answer }}>
          </div>
        </div>
      ))}
      
    </div>
  );
}

export default TaylorBiggarFAQ;
