/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Throbber from '../throbber';
import AccountManager from '../../managers/Account';

function LoyaltyReferrals({ assetId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [referralCode, setReferralCode] = useState('');
  const [fullReferralCode, setFullReferralCode] = useState('');

  const copyToClipboard = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(fullReferralCode);
    toast.success('Referral url has been copied to your clipboard.');
  };

  const generateReferralUrl = (rawUrl) => {
    const { host } = window.location;
    const justtheCode = rawUrl.split('referralCode=')[1];
    const referralUrl = `https://${host}?referralCode=${justtheCode}`;
    setFullReferralCode(referralUrl);
    return referralUrl;
  };

  const getReferralUrl = async () => {
    try {
      const referralUrl = await AccountManager.getReferralLink(AccountManager.getToken(), assetId);
      if (referralUrl && referralUrl.success) {
        setIsLoading(false);
        setReferralCode(generateReferralUrl(referralUrl.referralUrl));
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      toast.error(error ? error.message : 'An error occurred while getting your referral code. Please try again later.');
    }
  };

  useEffect(() => {
    getReferralUrl();
  }, []);

  return (
    <div className="loyalty-modal">
      {isLoading && <Throbber throbberText="Getting your referral code! One second..." />}
      <div className="loyalty-modal__header">
        <div className="loyalty-modal__title">Share your referral link and earn points</div>
        <div className="loyalty-modal__description">Once your friend signs up, 20 points will be added to both of your accounts.</div>
      </div>
      <div className="loyalty-modal__content">
        <div>
          <label htmlFor="referralCode" className="loyalty-modal__subtitle">Copy Referral Link:</label>
          <div className="loyalty-modal__referral--container">
            <input type="text" id="referralCode" value={referralCode} readOnly />
            <a
                href="#"
                className="button-nav loyalty-modal__referral--copy"
                onClick={(e) => copyToClipboard(e)}>
              Copy Link
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoyaltyReferrals;
