import React, { Component } from 'react';
import LoyaltyLanding from '../components/loyalty/loyaltyLanding';
import HowItWorks from '../components/edrop/howItWorks';
import LoyaltyBenefits from '../components/loyalty/taylorbiggarBenefits';
import LoyaltyFlexContent from '../components/loyalty/loyaltyFlexContent';
import CandidSoulTiers from '../components/loyalty/candidSoulTiers';
import UtilityManager from '../managers/Utility';
import { getParameterByName } from '../managers/Helpers';
import TaylorBiggarFAQ from '../components/loyalty/taylorbiggarFAQ';

class Setup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatorData: {},
      transferToken: null,
      transferCookie: null,
      asset: null,
      showModal: true,
      benefits: [],
      language: 'en',
    };
  }

  setTransferToken = (token) => {
    Object.entries(localStorage).map((obj) => {
      const key = obj[0];
      if (key.includes('MEMBERSHIP_TRANSFER')) {
        sessionStorage.removeItem(key);
      }
      return true;
    });
    // force the one in the URL to be in memory
    this.setState({
      transferCookie: 'MEMBERSHIP_TRANSFER_' + token,
      transferToken: token,
    });
    // localStorage.setItem('MEMBERSHIP_TRANSFER_' + token, token);
  }

  componentDidMount() {
    document.title = process.env.CREATOR_SUBDOMAIN === 'account' ? 'ethos' : process.env.SITE_NAME + ' | Join the ' + process.env.DIGITAL_ASSET_TYPE;
    if (getParameterByName('id', window.location.href)) {
      this.setTransferToken(getParameterByName('id', window.location.href));
    } else if (process.env.EDROP_CLAIM_TOKEN) {
      this.setTransferToken(process.env.EDROP_CLAIM_TOKEN);
    } else {
      window.location.href = '/';
    }
  }

  loadTransferDetails = async (transferDetails) => {
    if (transferDetails.asset) {
      this.setState({
        asset: transferDetails.asset,
        showModal: false,
      });
    }
    // get any preview benefits
    const previewBenefits = await UtilityManager.getPreviewUtility(transferDetails.asset.assetId);
    if (previewBenefits && previewBenefits.length > 0) {
      this.setState({
        benefits: previewBenefits,
      });
    }
  }

  componentWillUnmount() {
    document.title = process.env.SITE_NAME;
  }

  render() {
    const {
      language, showModal, transferCookie, transferToken, membershipFeatures,
    } = this.state;
    return (
      <div>
        <LoyaltyLanding lang={language} onTransferDetailsLoaded={(transferDetails) => this.loadTransferDetails(transferDetails)} transferCookie={transferCookie} transferToken={transferToken} />
        
        <HowItWorks lang={language} />

        {/* <LoyaltyBenefits lang={language} /> */}

        <LoyaltyFlexContent lang={language} />

        {/* <CandidSoulTiers lang={language} /> */}

        <div className="storefront-faq">
          <div className="storefront-faq--title">FAQ</div>
          <TaylorBiggarFAQ noHeader lang={language} />
        </div>
      </div>
    );
  }
}

export default Setup;
